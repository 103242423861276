<template>
    <div>
          <b-modal ref="modal_transferencia" centered id="modal_transferencia" title="Transfência de estoque">
              <div class="form">
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="col-md-12">{{$t('DATATRANSF.NOME')}}</label>
                    <input
                      required
                      type="date"
                      class="form-control"
                      v-model="form_transferencia.data_transferencia"
                    />
                  </div>

                  <div class="col-md-6">
                    <label class="col-md-12">{{$t('CANTI.NOME')}}</label>
                    <input
                      required
                      type="date"
                      class="form-control"
                      v-model="form_transferencia.quantidade"
                    />
                  </div>

                  

                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="col-md-12">{{$t('AFRACCION.NOME')}}</label>
                    <input
                      required disabled
                      type="date"
                      class="form-control"
                      v-model="form_transferencia.fracao"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-12">Filial de destino:*</label>
                    <input
                      required
                      type="number"
                      class="form-control"
                      v-model="form_transferencia.estoque_quantidade"
                    />
                  </div>
                </div>
              
              </div>
         
              <template #modal-footer>
                <div class="w-100">
                  <div class="text-center">
                  <button
                    @click.prevent="confirm('coloca no estoque')"
                    class="btn btn-primary"
                    :disabled="verif"
                  >
                  {{$t('GERENCIAR.NOME')}}
                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button>
              </div>
                  <!-- <b-button
                    variant="danger"
                    
                    class=" btn-light float-right mt-3"
                    @click="$bvModal.hide('estoque')"
                    >Fechar</b-button
                  > -->
                </div>
              </template>
            </b-modal>
    </div>
</template>

<script>

export default {
 
  data() {
    return {
        form_transferencia:{
            estoque_quantidade:null,
            estoque_fracao:'',
            data_transferencia:'',
            filial_id:''
        },
     
    };
  },

  created() {
    // this.listar_filia();
  },
  computed: {
    
    lista_produtos() {
          return this.$store.state.produto.lista_produtos.filter(item => {
        if (!this.pesquisa.texto) return true
        return String(item[this.pesquisa.tipo]).toLowerCase().indexOf(this.pesquisa.texto.toLowerCase()) !== -1
      });
     
    },
 
  },
  methods: {

    // async listar_produtos() {
    //   this.$store.dispatch("configEmpresa/MudarPreloader", true);
    //   await this.$store.dispatch("produto/listar_produtos").finally(() => {
    //     this.$store.dispatch("configEmpresa/MudarPreloader", false);
    //     this.$store.dispatch("produto/listar_produto", "");
    //       // localStorage.removeItem('produto');
            
    //   });
    // }
  },
};
</script>
